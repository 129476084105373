<template>
  <v-responsive :aspect-ratio="16/9">
    <v-card-text class="py-0" v-if="items.THONG_TIN !== null && items.THONG_TIN !== undefined && items.THONG_TIN.length > 0">
      <v-timeline dense>
        <v-slide-x-reverse-transition
          group
          hide-on-leave
        >
          <v-timeline-item
            v-for="(item,index) in items.THONG_TIN"
            :key="index"
            :color="(item.TRANG_THAI === 'Phát thành công') ? 'green':'blue'"
            :icon="(item.TRANG_THAI === 'Phát thành công') ? 'mdi-truck-check':'mdi-truck-delivery'"
            small
            fill-dot
          >
            <v-alert
              :value="true"
              :color="(item.TRANG_THAI === 'Phát thành công') ? 'green':'blue'"
              :icon="(item.TRANG_THAI === 'Phát thành công') ? 'mdi-truck-check':'mdi-truck-delivery'"
              class="white--text"
            >
              <p>{{item.NGAY}} - {{item.GIO}} - {{item.TRANG_THAI}}</p>
              <p>{{item.TRANG_THAI}} - {{item.VI_TRI}} - {{item.DIEN_THOAI}}</p>
            </v-alert>
          </v-timeline-item>
        </v-slide-x-reverse-transition>
      </v-timeline>
    </v-card-text>
  </v-responsive>
</template>

<script>
export default {
  props: ['items'],
}
</script>

<style>

</style>