<template>
  <div class="team">
    <v-container >
      <v-layout row wrap>
         <v-flex
            sm12
            xs12
            md12
            lg12
          >
          <template>
            <v-card>
              <v-toolbar color="#0f2e8c" flat>
                <h3 class="white--text">KIỂM TRA TRACK VNPOST</h3>
              </v-toolbar>
              <v-card-title>
                <v-text-field
                  v-model="Track"
                  label="Nhập thông tin tracking VNPOST"
                  @change="Check"
                  @keyup.native.enter="Check"
                />
              </v-card-title>
              <v-card-text>
                <Tracking :items="TrackData" />
              </v-card-text>
              <v-overlay
                absolute
                :value="loading"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="loading"
                />
              </v-overlay>
            </v-card>
          </template>
         </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { Request } from '@/plugins/helper/fetch'
import { Status } from '@/plugins/helper/dstatus'
import Tracking from '@/components/tracking/List.vue'
export default {
  name: 'tracking',
  components: {
    Tracking,
  },
  data () {
    return {
      loading: false,
      Track: '',
      TrackData: [],
      notice: new Status(this.$swal),
      req: new Request('tracking'),
    }
  },
  computed: {
  },
  methods: {
    Check(){
      const Track = this.Track.trim()
      if(Track){
        this.loading = true
        this.req.fetch(Track).then(resp=>{
          return resp.json()
        })
        .then(json=>{
          if(json.success === true){
            this.TrackData = json.data
          }else{
            const e = json.error || "Không thể kiểm tra thông tin tracking của bạn !!!"
            this.notice.error(e)
          }
        })
        .catch(e=>{
          this.notice.error(e)
        })
        .finally(()=>{
          this.loading = false
        })
      }else{
        this.notice.error("Vui lòng nhập thông tin tracking !!!")
      }
    }
  },
  mounted(){
    
  }
}
</script>
